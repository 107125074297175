import { createStore } from 'vuex'

export default createStore({
  state: {
    counter: 0, // Licznik kliknięć
  },
  getters: {
    getCounter(state) {
      return state.counter
    },
  },
  mutations: {
    incrementCounter(state) {
      state.counter++
    },
    DECREMENT_COUNTER(state) {
      state.counter--
    },
  },
  actions: {
    incrementCounter({ commit }) {
      commit('incrementCounter')
    },
  },
  modules: {},
})
