import { createI18n } from 'vue-i18n'

// Tłumaczenia dla polskiego i angielskiego
const messages = {
  en: {
    title: 'Job Application Feedback Survey',
    question: 'Is my application still being considered?',
    recruitment_status: 'Is the recruitment process still ongoing?',
    cv_issue: 'What was wrong with my CV?',
    cv_improvement: 'What could I improve in my CV?',
    submit: 'Submit',
    feedback_thanks: 'Thank you for your feedback!',
    yes: 'Yes',
    no: 'No',
    ongoing: 'Ongoing',
    not_sure: 'Not sure',
    select_option: 'Select an option',
    placeholder_details: 'Please provide details',
    placeholder_suggestions: 'Your suggestions',
    expected_end_date: 'Expected end date',
    rejection_reason: 'What was the reason for rejecting my application?',
    placeholder_rejection_reason:
      'Please provide the reason for the rejection, as it will help me improve my skills and better align with the requirements in the future. I would appreciate any feedback or suggestions that could assist me in further development.',
    additional_info:
      'Please provide any additional details relevant to your recruitment process:',
    additional_details_placeholder: 'Enter any additional information here...',
    application_feedback: 'Could you provide feedback on areas of improvement?',
    placeholder_application_feedback:
      'Enter feedback on areas of improvement here...',
    skills_experience_gap:
      'Were there any skills or experiences I was lacking?',
    placeholder_skills_experience_gap:
      'Enter details on skill or experience gaps...',
  },
  pl: {
    title: 'Ankieta dotycząca rekrutacji',
    recruitment_status: 'Czy proces rekrutacyjny nadal trwa?',
    question: 'Czy moja kandydatura jest nadal rozważana?',
    cv_issue: 'Co było nie tak w moim CV?',
    cv_improvement: 'Co mogę poprawić w moim CV?',
    submit: 'Zatwierdź',
    feedback_thanks: 'Dziękuję za Twoją opinię!',
    yes: 'Tak',
    no: 'Nie',
    ongoing: 'W trakcie',
    not_sure: 'Nie jestem pewien',
    select_option: 'Wybierz opcję',
    placeholder_details: 'Proszę podać szczegóły',
    placeholder_suggestions: 'Twoje sugestie',
    expected_end_date: 'Szacowany czas zakończenia rekrutacji',
    rejection_reason: 'Jaka była przyczyna odrzucenia mojej kandydatury?',
    placeholder_rejection_reason:
      'Proszę podać przyczynę odrzucenia, pomoże mi to w poprawie swoich umiejętności i lepszym dostosowaniu się do wymagań w przyszłości. Będę wdzięczny za wszelkie wskazówki, które mogą pomóc mi w dalszym rozwoju.',
    additional_info:
      'Proszę podać dodatkowe informacje dotyczące procesu rekrutacyjnego:',
    additional_details_placeholder: 'Wprowadź tutaj dodatkowe informacje...',
    application_feedback: 'Czy mogliby Państwo wskazać, co mogę poprawić?',
    placeholder_application_feedback:
      'Wprowadź informacje na temat obszarów do poprawy...',
    skills_experience_gap:
      'Czy były jakieś umiejętności lub doświadczenia, których mi brakowało?',
    placeholder_skills_experience_gap:
      'Wprowadź szczegóły dotyczące braków w umiejętnościach lub doświadczeniu...',
  },
}

const i18n = createI18n({
  locale: 'en', // Domyślny język
  fallbackLocale: 'pl', // Zapasowy język
  messages,
})

export default i18n
