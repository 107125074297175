import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/css/tailwind.css'
import './assets/css/style.css'
import i18n from './i18n'

// Tworzenie aplikacji i podłączenie routera, store, i18n itp.
createApp(App)
  .use(store) // Dodanie Vuex (store)
  .use(router) // Dodanie Vue Router
  .use(i18n) // Dodanie i18n dla tłumaczeń
  .mount('#app') // Montowanie aplikacji na elemencie z id #app
