<template>
  <div class="relative px-2">
    <div class="max-w-md mx-auto">
      <div class="row flex items-center justify-center">
        <!-- <h1 class="text-2xl font-bold mb-2">YouTube Search with no Ads</h1> -->
      </div>

      <div class="flex justify-center items-center gap-4 mb-4">
        <input
          v-model="query"
          @keyup.enter="search"
          type="text"
          placeholder="Search for videos..."
          class="block w-full px-4 py-3 text-base text-primary-dark bg-primary-light border border-secondary-dark rounded-full shadow-lg placeholder-secondary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark focus:border-primary-dark transition-transform duration-300 ease-in-out transform-gpu"
        />
        <button
          @click="search"
          class="px-6 py-3 text-base font-medium bg-customGreen text-white hover:text-customGreenLight rounded-full shadow-lg hover:bg-customDarkBlue transition-transform duration-300 ease-in-out transform-gpu"
        >
          Go!
        </button>
      </div>
    </div>

    <div class="p-4">
      <div v-if="loading" class="text-center">Loading...</div>
      <div
        v-if="!loading && videos.length"
        class="bg-customDarkLight max-w-screen-2xl mx-auto p-12 relative"
      >
        <p class="mt-2 text-m font-semibold text-center pb-2">
          <span v-if="candlesCaught <= 0" class="text-customGreenLight"
            >Złap wiecej zniczy🔥</span
          >
          <span v-else> Dostępne zapisy: {{ candlesCaught }}</span>
        </p>

        <div class="relative w-full max-w-3xl shadow-4xl mx-auto flex">
          <YouTube
            :video-id="currentVideoId"
            :src="currentVideoUrl"
            @ready="onPlayerReady"
            class="w-full mx-auto flex justify-center h-64 md:h-[27rem] overflow-hidden shadow-lg"
          />

          <button
            v-if="candlesCaught > 0"
            @click="saveVideo"
            :disabled="saving || videos[currentIndex]?.isSaved"
            :class="{
              'cursor-not-allowed': saving || videos[currentIndex]?.isSaved,
            }"
            class="px-6 py-2 absolute right-0 -top-7 text-base font-medium bg-customDark text-white shadow-lg hover:text-customGreenLight transition-transform duration-300 ease-in-out transform-gpu flex items-center justify-center"
          >
            <i
              :class="{
                'fas fa-circle-notch fa-spin': saving,
                'fas fa-check': saveDone || videos[currentIndex]?.isSaved,
              }"
            ></i>
            <span v-if="saving" class="ml-2">Saving...</span>
            <span v-else-if="saveDone" class="ml-2">Done</span>
            <span v-else-if="videos[currentIndex]?.isSaved" class="ml-2"
              >Saved</span
            >
            <span v-else class="">Save</span>
          </button>

          <button
            @click="nextVideo"
            class="absolute right-0 -bottom-7 px-6 py-2 text-base font-medium bg-customDark text-white hover:text-customGreenLight shadow-lg hover:bg-customDarkBlue transition-transform duration-300 ease-in-out transform-gpu"
          >
            Next
          </button>
        </div>
        <p class="mt-2 text-lg font-semibold text-center pt-6">
          {{ currentVideoTitle }}
        </p>
        <p class="mt-2 text-lg font-semibold text-center pb-2">
          Time left: {{ remainingTime }} seconds
        </p>

        <!-- Miniaturki kolejnych filmów -->
        <div class="mt-4 flex overflow-x-auto space-x-4 justify-center">
          <div
            v-for="(video, index) in upcomingVideos"
            :key="video.videoId"
            class="relative cursor-pointer shadow-lg"
            @click="currentIndex = video.index"
            :class="{
              'border-4 border-green-500 p-12 bg-customDarkLight':
                currentIndex === video.index,
            }"
          >
            <img
              :src="video.thumbnail"
              class="w-40 h-34 object-cover shadow-lg"
              :alt="video.title"
            />
            <div
              v-if="index === 0"
              class="absolute top-0 right-0 bg-green-500 text-white text-xs px-1 py-0.5 rounded-bl-lg"
            >
              Next
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!loading && !videos.length && !error"
        class="mt-4 text-center"
      ></div>
      <div v-if="error" class="mt-4 text-red-500 text-center">
        Error: {{ error.message }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'
import YouTube from 'vue3-youtube'
import apiClient from '@/axios'
import { useStore } from 'vuex'

const query = ref('')
const videos = ref<any[]>([])
const loading = ref(false)
const error = ref<Error | null>(null)
const currentIndex = ref(0)
const saving = ref(false)
const saveDone = ref(false)
const savedVideos = ref<string[]>([])
const savedVideosKey = 'savedVideos'

const store = useStore()

// Dokończyć Dostępne zapisy: nie działa poprawić  złączenie zapisu po kliknięciu snicha znicza
const candlesCaught = computed(() => store.getters.getCounter)

// Funkcja ładująca zapisane filmy z Local Storage
const loadSavedVideos = () => {
  const saved = localStorage.getItem(savedVideosKey)
  savedVideos.value = saved ? JSON.parse(saved) : []
}

// Funkcja zapisująca stan w Local Storage
const saveVideosToLocalStorage = () => {
  localStorage.setItem(savedVideosKey, JSON.stringify(savedVideos.value))
}

// Fetch saved videos on component mount
const fetchVideos = async () => {
  loading.value = true
  error.value = null

  try {
    const response = await apiClient.get('/api/youtube/videos/all')
    savedVideos.value = response.data.map((video: any) => video.video_id)
  } catch (err) {
    error.value = err as Error
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  fetchVideos()
  loadSavedVideos()
})

const search = async () => {
  if (query.value.trim() === '') {
    return
  }

  loading.value = true
  error.value = null

  try {
    const response = await apiClient.get('/api/youtube/search', {
      params: { query: query.value },
    })
    videos.value = response.data.map((video: any, index: number) => ({
      ...video,
      index, // Store original index
      isSaved: savedVideos.value.includes(video.videoId),
    }))
    console.log('Videos data:', videos.value) // Debugging log

    currentIndex.value = 0
  } catch (err) {
    error.value = err as Error
  } finally {
    loading.value = false
  }
}

const currentVideoId = computed(() => {
  return videos.value[currentIndex.value]?.videoId || ''
})

const currentVideoTitle = computed(() => {
  return videos.value[currentIndex.value]?.title || ''
})

const currentVideoUrl = computed(() => {
  return `https://www.youtube.com/embed/${
    videos.value[currentIndex.value]?.videoId
  }?autoplay=1`
})

const currentVideoThumbnail = computed(() => {
  return videos.value[currentIndex.value]?.thumbnail || ''
})

const currentVideoDuration = computed(() => {
  return videos.value[currentIndex.value]?.duration || ''
})

const currentVideoChannelId = computed(() => {
  return videos.value[currentIndex.value]?.channelId || ''
})

const currentVideoChannelTitle = computed(() => {
  return videos.value[currentIndex.value]?.channelTitle || ''
})

const nextVideo = () => {
  if (currentIndex.value < videos.value.length - 1) {
    // Move current video to the end of the upcoming videos
    const currentVideo = videos.value[currentIndex.value]
    videos.value.push(currentVideo)
    currentIndex.value++
  }
}

const saveVideo = async () => {
  store.commit('DECREMENT_COUNTER')

  const currentVideo = videos.value[currentIndex.value]

  saving.value = true
  saveDone.value = false
  // Collect device information
  const deviceInfo = {
    // Podstawowe informacje o przeglądarce i urządzeniu
    userAgent: navigator.userAgent, // Identyfikator przeglądarki
    platform: navigator.platform, // Platforma systemowa (np. "Win32")
    language: navigator.language, // Główny język przeglądarki
    languages: navigator.languages, // Lista preferowanych języków
    onlineStatus: navigator.onLine, // Status połączenia online (true/false)

    // Informacje o ekranie i oknie przeglądarki
    screenResolution: `${window.screen.width}x${window.screen.height}`, // Rozdzielczość ekranu
    availableScreenResolution: `${window.screen.availWidth}x${window.screen.availHeight}`, // Rozdzielczość ekranu dostępna dla przeglądarki
    windowSize: `${window.innerWidth}x${window.innerHeight}`, // Rozmiar okna przeglądarki
    colorDepth: window.screen.colorDepth, // Głębia kolorów (bitów na piksel)

    // Informacje o urządzeniu
    touchSupport: 'ontouchstart' in window || navigator.maxTouchPoints > 0, // Obsługa dotyku
    deviceMemory: navigator.deviceMemory || 'Unknown', // Ilość pamięci RAM urządzenia (w GB, jeśli wspierane)
    hardwareConcurrency: navigator.hardwareConcurrency || 'Unknown', // Liczba rdzeni procesora

    // Informacje o połączeniu sieciowym
    connectionType: navigator.connection?.effectiveType || 'Unknown', // Typ połączenia (np. "4g")
    connectionSpeed: navigator.connection?.downlink || 'Unknown', // Prędkość pobierania (w Mbps)
    connectionRTT: navigator.connection?.rtt || 'Unknown', // Opóźnienie połączenia (w ms)

    // Strefa czasowa i czas
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Strefa czasowa (np. "Europe/Warsaw")
    timezoneOffset: new Date().getTimezoneOffset(), // Różnica czasu względem UTC (w minutach)
    currentDateUTC: new Date().toISOString(), // Aktualna data i czas w UTC

    // Bezpieczeństwo i protokół
    isSecureContext: window.isSecureContext, // Czy strona działa w bezpiecznym kontekście (HTTPS)
    cookieEnabled: navigator.cookieEnabled, // Czy przeglądarka obsługuje pliki cookie

    // Lokalizacja użytkownika (wymaga zgody użytkownika)
    location: await new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy, // Dokładność w metrach
          })
        },
        (error) => {
          resolve('Location access denied')
        }
      )
    }),

    // Adres IP
    ip: await fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => data.ip)
      .catch(() => 'Unknown'),
  }

  try {
    await apiClient.post('/api/youtube/save', {
      video_id: currentVideo.videoId,
      title: currentVideoTitle.value,
      url: currentVideoUrl.value,
      thumbnail: currentVideoThumbnail.value,
      duration: currentVideoDuration.value,
      channel_id: currentVideoChannelId.value,
      channel_title: currentVideoChannelTitle.value,
      device_info: JSON.stringify(deviceInfo),
    })

    savedVideos.value.push(currentVideo.videoId)

    // Zapisz do Local Storage
    saveVideosToLocalStorage()

    currentVideo.isSaved = true
    saveDone.value = true
  } catch (err) {
    console.error('Error saving video:', err)
  } finally {
    saving.value = false

    setTimeout(() => {
      saveDone.value = false
    }, 2000)
  }
}

// const saveVideo = async () => {
//   const currentVideo = videos.value[currentIndex.value]

//   store.commit('DECREMENT_COUNTER')

//   if (saving.value || savedVideos.value.includes(currentVideo.videoId)) return

//   saving.value = true
//   saveDone.value = false

//   try {
//     // Dodaj film do zapisanych
//     savedVideos.value.push(currentVideo.videoId)
//     currentVideo.isSaved = true

//     saveDone.value = true
//   } catch (err) {
//     console.error('Error saving video:', err)
//   } finally {
//     saving.value = false

//     setTimeout(() => {
//       saveDone.value = false
//     }, 2000)
//   }
// }

// const saveVideo = async () => {
//   if (savedVideos.value.length >= candlesCaught.value) {
//     alert('Nie możesz zapisać więcej filmów. Złap więcej zniczy!')
//     return
//   }

//   const currentVideo = videos.value[currentIndex.value]

//   if (saving.value || savedVideos.value.includes(currentVideo.videoId)) return

//   saving.value = true
//   saveDone.value = false
//   // Collect device information
//   const deviceInfo = {
//     // Podstawowe informacje o przeglądarce i urządzeniu
//     userAgent: navigator.userAgent, // Identyfikator przeglądarki
//     platform: navigator.platform, // Platforma systemowa (np. "Win32")
//     language: navigator.language, // Główny język przeglądarki
//     languages: navigator.languages, // Lista preferowanych języków
//     onlineStatus: navigator.onLine, // Status połączenia online (true/false)

//     // Informacje o ekranie i oknie przeglądarki
//     screenResolution: `${window.screen.width}x${window.screen.height}`, // Rozdzielczość ekranu
//     availableScreenResolution: `${window.screen.availWidth}x${window.screen.availHeight}`, // Rozdzielczość ekranu dostępna dla przeglądarki
//     windowSize: `${window.innerWidth}x${window.innerHeight}`, // Rozmiar okna przeglądarki
//     colorDepth: window.screen.colorDepth, // Głębia kolorów (bitów na piksel)

//     // Informacje o urządzeniu
//     touchSupport: 'ontouchstart' in window || navigator.maxTouchPoints > 0, // Obsługa dotyku
//     deviceMemory: navigator.deviceMemory || 'Unknown', // Ilość pamięci RAM urządzenia (w GB, jeśli wspierane)
//     hardwareConcurrency: navigator.hardwareConcurrency || 'Unknown', // Liczba rdzeni procesora

//     // Informacje o połączeniu sieciowym
//     connectionType: navigator.connection?.effectiveType || 'Unknown', // Typ połączenia (np. "4g")
//     connectionSpeed: navigator.connection?.downlink || 'Unknown', // Prędkość pobierania (w Mbps)
//     connectionRTT: navigator.connection?.rtt || 'Unknown', // Opóźnienie połączenia (w ms)

//     // Strefa czasowa i czas
//     timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Strefa czasowa (np. "Europe/Warsaw")
//     timezoneOffset: new Date().getTimezoneOffset(), // Różnica czasu względem UTC (w minutach)
//     currentDateUTC: new Date().toISOString(), // Aktualna data i czas w UTC

//     // Bezpieczeństwo i protokół
//     isSecureContext: window.isSecureContext, // Czy strona działa w bezpiecznym kontekście (HTTPS)
//     cookieEnabled: navigator.cookieEnabled, // Czy przeglądarka obsługuje pliki cookie

//     // Lokalizacja użytkownika (wymaga zgody użytkownika)
//     location: await new Promise((resolve) => {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           resolve({
//             latitude: position.coords.latitude,
//             longitude: position.coords.longitude,
//             accuracy: position.coords.accuracy, // Dokładność w metrach
//           })
//         },
//         (error) => {
//           resolve('Location access denied')
//         }
//       )
//     }),

//     // Adres IP
//     ip: await fetch('https://api.ipify.org?format=json')
//       .then((response) => response.json())
//       .then((data) => data.ip)
//       .catch(() => 'Unknown'),
//   }

//   try {
//     await apiClient.post('/api/youtube/save', {
//       video_id: currentVideo.videoId,
//       title: currentVideoTitle.value,
//       url: currentVideoUrl.value,
//       thumbnail: currentVideoThumbnail.value,
//       duration: currentVideoDuration.value,
//       channel_id: currentVideoChannelId.value,
//       channel_title: currentVideoChannelTitle.value,
//       device_info: JSON.stringify(deviceInfo),
//     })

//     savedVideos.value.push(currentVideo.videoId)
//     currentVideo.isSaved = true
//     saveDone.value = true
//   } catch (err) {
//     console.error('Error saving video:', err)
//   } finally {
//     saving.value = false

//     setTimeout(() => {
//       saveDone.value = false
//     }, 2000)
//   }
// }

// Compute upcoming videos
const upcomingVideos = computed(() => {
  if (!videos.value) return []

  const start = currentIndex.value + 1
  const end = start + 3
  return videos.value.slice(start, end)
})

const player = ref<any>(null) // Referencja do instancji odtwarzacza
const remainingTime = ref<number>(0)

const onPlayerReady = (event: any) => {
  player.value = event.target // Przypisanie instancji odtwarzacza

  // Nasłuchiwanie na zmiany stanu odtwarzacza
  player.value.addEventListener('onStateChange', onPlayerStateChange)
}

const onPlayerStateChange = (event: any) => {
  if (event.data === 0) {
    // '0' oznacza, że film się skończył
    playNextVideo() // Uruchom następny film
  }
}

const playNextVideo = () => {
  if (currentIndex.value < videos.value.length - 1) {
    currentIndex.value++ // Zwiększ indeks bieżącego filmu
  } else {
    currentIndex.value = 0 // Powróć do pierwszego filmu, jeśli to był ostatni
  }
}

// Metoda do obliczenia czasu pozostałego do końca filmu
const calculateRemainingTime = () => {
  if (player.value) {
    const currentTime = player.value.getCurrentTime() // Czas aktualny
    const duration = player.value.getDuration() // Całkowity czas
    remainingTime.value = Math.ceil(duration - currentTime) // Zaokrąglenie w górę do pełnych sekund
  }
}

// Aktualizowanie czasu co sekundę
setInterval(() => {
  calculateRemainingTime()
}, 1000)
</script>

<style scoped lang="scss">
.white-shadow {
  box-shadow: 0 4px 20px rgba(2, 29, 10, 0.5);
}

.shadow-4xl.white-shadow {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25),
    0 4px 20px rgba(1, 17, 10, 0.5);
}

.border-4 {
  border-width: 4px;
}

.border-green-500 {
  border-color: #48bb78; /* Zielony kolor, możesz dostosować */
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-trigger {
  cursor: pointer;
  padding-bottom: 2em;
  font-size: 1em;
}

.tooltip-content {
  display: none;
  position: absolute;
  background-color: #48bb78da;
  border: 1px solid #48bb78;
  border-radius: 20px;
  padding: 10px;
  z-index: 1;
  left: auto;
  right: 0;
  top: 100%; /* Position it below the trigger */
  white-space: normal; /* Allow text to wrap */
  text-align: left;
  width: 300px; /* Adjust width as needed */
  color: #fff;

  p {
    padding-block: 5px;
  }
}

.tooltip-container:hover .tooltip-content {
  display: block;
}

ol {
  margin: 0;
  padding-left: 20px;
  list-style-type: decimal; /* Styl numerów */
}

li {
  margin-bottom: 8px; /* Adjust spacing between list items */
}
</style>
