<template>
  <div>
    <div ref="snitch" class="snitch" @click="catchSnitch"></div>

    <!-- Losowe sentencje motywacyjne -->
    <div
      v-for="(quote, index) in activeQuotes"
      :key="index"
      :style="quote.style"
      class="quote"
    >
      {{ quote.text }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore()

const counter = computed(() => store.getters.getCounter)

const activeQuotes = ref<{ text: string; style: any }[]>([])

const moveSnitch = (element: HTMLElement) => {
  const containerWidth = window.innerWidth - 50
  const containerHeight = window.innerHeight - 50

  function randomPosition() {
    const randomX = Math.random() * containerWidth
    const randomY = Math.random() * containerHeight
    return { x: randomX, y: randomY }
  }

  function animate() {
    const { x, y } = randomPosition()
    element.style.top = `${y}px`
    element.style.left = `${x}px`
    setTimeout(animate, Math.random() * 2000 + 1000)
  }

  animate()
}

const catchSnitch = () => {
  const snitch = document.querySelector('.snitch') as HTMLElement
  moveSnitch(snitch)
  store.dispatch('incrementCounter')
}

onMounted(() => {
  const snitch = document.querySelector('.snitch') as HTMLElement
  if (snitch) {
    moveSnitch(snitch)
  }
})
</script>

<style scoped lang="scss">
.snitch {
  width: 50px;
  height: 50px;
  background: radial-gradient(circle at 30% 30%, #ffd700, #ffa500);
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease;
  animation: flame 1.5s infinite ease-in-out, flutter 0.5s infinite ease-in-out;
  box-shadow: 0 0 20px 5px rgba(255, 223, 0, 0.7),
    0 0 40px 15px rgba(255, 223, 0, 0.4), 0 0 60px 30px rgba(255, 223, 0, 0.2);

  &::before,
  &::after {
    content: '';
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
  }

  &::before {
    width: 15px;
    height: 15px;
    top: 25%;
    left: 25%;
    filter: blur(2px);
  }

  &::after {
    width: 8px;
    height: 8px;
    top: 40%;
    left: 40%;
    filter: blur(1px);
  }

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0 30px 10px rgba(255, 223, 0, 0.8),
      0 0 50px 20px rgba(255, 223, 0, 0.6), 0 0 70px 40px rgba(255, 223, 0, 0.4);
    cursor: pointer;
  }
}

@keyframes flutter {
  0%,
  100% {
    transform: rotate(-2deg) scale(1);
  }
  50% {
    transform: rotate(2deg) scale(1.05);
  }
}

@keyframes flame {
  0% {
    filter: brightness(100%) blur(0px);
    box-shadow: 0 0 20px rgba(255, 165, 0, 0.8), 0 0 40px rgba(255, 165, 0, 0.6);
  }
  50% {
    filter: brightness(120%) blur(1px);
    box-shadow: 0 0 40px rgba(255, 165, 0, 0.8), 0 0 80px rgba(255, 165, 0, 0.6);
  }
  100% {
    filter: brightness(100%) blur(0px);
    box-shadow: 0 0 20px rgba(255, 165, 0, 0.8), 0 0 40px rgba(255, 165, 0, 0.6);
  }
}
</style>
