<template>
  <div class="wi-bg flex-center flex-col relative pb-6">
    <!-- Dodany 'relative' -->
    <YoutubeSearch class="" />

    <LastSavedComponent :key="2" />

    <SnitchComponent />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import YoutubeSearch from '@/components/YoutubeSearch.vue'
import SnitchComponent from '@/components/SnitchComponent.vue'
import LastSavedComponent from '@/components/LastSavedComponent.vue'

const showSnitch = ref(false)
</script>

<style scoped></style>
